import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/Service/http-service.service';
import { MessageService} from 'primeng/api';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
  providers: [MessageService]
})
export class RegistrationComponent {
  registerForm :any= FormGroup
  submitted:boolean=false

  constructor(private fb:FormBuilder,private httpService:HttpServiceService,private router: Router,
    private messageService:MessageService){}

  ngOnInit(){
    this.validationInisilize()
  }

  validationInisilize(){
    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required,Validators.email],
      password: ['', [Validators.required, Validators.minLength(6)]],
      // conformPassword: ['',[Validators.required]],
      mobilenumber:['', Validators.required]
  });
  }

   get f() { return this.registerForm.controls; }

  onSubmit(){
    this.submitted=true;
    if(this.registerForm.invalid){
      return;
    }
     this.registerd()
    }

    registerd(){
      const registerd_Data =this.registerForm.value
      this.httpService.post('auth/register',registerd_Data).subscribe(data => {
        // for popup and navigate
          this.messageService.add({severity:'success', summary: 'Registration successful', detail: ''});
                    this.router.navigate(['/']);
                },
                error => {
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Message Content' });
                });
    }

    // navigate
    goToAnotherLogin() {
      this.submitted=true;
      this.router.navigate(['/']);
    }

  }



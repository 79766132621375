<p-toast possition="bottom-center"></p-toast>
   <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 form" >
                <div class="card p-5 m-5">
                    <h2>REGISTRATION</h2>

                    <form class="mt-4" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                           <span class="p-float-label mb-4">
                                <input pInputText class="p-inputtext-sm "  id="username" formControlName="name"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                <label for="username">Username</label>
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">required</div>
                                </div>
                            </span>
                                                       
                            <span class="p-float-label mb-4">
                                <input pInputText class="p-inputtext-sm" id="email" formControlName="email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">required</div>
                                    <div *ngIf="f.email.errors.email">this is invalid email</div>
                                </div>
                                <label for="password">Email</label>
                            </span>
        
                            <span class="p-float-label mb-4">
                                <input pInputText class="p-inputtext-sm" id="password" formControlName="password"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">required</div>
                                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                </div>
                                <label for="password">Password</label>
                            </span>
        
                            <!-- <span class="p-float-label mb-4">
                                <input pInputText class="p-inputtext-sm" id="conformPassword" formControlName="conformPassword"
                                [ngClass]="{ 'is-invalid': submitted && f.conformPassword.errors }" />
                                <div *ngIf="submitted && f.conformPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.conformPassword.errors.required">required</div>
                                    <div *ngIf="f.conformPassword.errors.mismatch">Passwords do not match</div>
                                </div>
                                <label for="conformPassword">Conform Password</label>
                            </span> -->
        
                            <span class="p-float-label mb-4">
                                <input pInputText class="p-inputtext-sm" id="mobilenumber" type="number" formControlName="mobilenumber"
                                [ngClass]="{ 'is-invalid': submitted && f.mobilenumber.errors }" />
                                <div *ngIf="submitted && f.mobilenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobilenumber.errors.required">required</div>
                                </div>
                                <label for="mobilenumber">Mobile No.</label>
                            </span>                    
                                               
                            <div class="button text-center d-flex mt-5">
                                <button type="submit" class=" btn savebtn btn-lg "
                                    style="padding-left: 2.5rem; padding-right: 2.5rem; font-size: larger;">Registered</button>
        
                                <button class="cancel-btn ms-3" type="button" (click)="goToAnotherLogin()">Cancel</button> 
                            </div>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    
                </div>
            </div>
        </div>
    </div>
    

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvalidPageComponent } from './invalid-page/invalid-page.component';
import { LoginGuardService } from './login-module/login-guard.service';

const routes: Routes = [
  { path: '', loadChildren: () => import('./login-module/login.module').then(m => m.LoginModule) },
  { path: 'pages', loadChildren: () => import('./content-module/content-module.module').then(m => m.ContentModuleModule), canActivate: [LoginGuardService], },
  { path: 'superadmin', loadChildren: () => import('./superadmin/superadmin.module').then(m => m.SuperadminModule), canActivate: [LoginGuardService], data: { expectedRole: 'superadmin' } },
  { path: 'surveyadmin', loadChildren: () => import('./surveyadmin/surveyadmin.module').then(m => m.SurveyadminModule), canActivate: [LoginGuardService], data: { expectedRole: 'surveyadmin' } },
  { path: 'division-coordinator', loadChildren: () => import('./division-coordinator/division-coordinator.module').then(m => m.DivisionCoordinatorModule), canActivate: [LoginGuardService], data: { expectedRole: 'division' } },
  { path: 'district-coordinator', loadChildren: () => import('./district-coordinator/district-coordinator.module').then(m => m.DistrictCoordinatorModule), canActivate: [LoginGuardService], data: { expectedRole: 'district' } },
  { path: 'block-coordinator', loadChildren: () => import('./block-coordinator/block-coordinator.module').then(m => m.BlockCoordinatorModule), canActivate: [LoginGuardService], data: { expectedRole: 'block' } },
  { path: 'sme-coordinator', loadChildren: () => import('./sme-coordinator/sme-coordinator.module').then(m => m.SmeCoordinatorModule), canActivate: [LoginGuardService], data: { expectedRole: 'SME' } },
  { path: 'subadmin', loadChildren: () => import('./subadmin/subadmin.module').then(m => m.SubadminModule), canActivate: [LoginGuardService], data: { expectedRole: 'subadmin' } },
  { path: '**', redirectTo: 'invalid' },

  { path: 'invalid', component: InvalidPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

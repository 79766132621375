import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { HttpServiceService } from '../Service/http-service.service';
import { CommunicationService } from '../Service/communication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {

  constructor(private router: Router, private httpService: HttpServiceService, private communicationService: CommunicationService) {
  }

  setNewRefreshToken() {
    const token = JSON.parse(sessionStorage.getItem('tokens') || '{}');
    this.httpService.post('auth/refresh-tokens', { refreshToken: token.refresh.token }).subscribe((res: any) => {
      if (res) {
        this.communicationService.setRefreshToken(res);
      }
    })
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let flag: boolean = false;
    const user = JSON.parse(sessionStorage.getItem('userProfile') || '{}');
    
    const role: any = route
    const expectedRole: any = role.data.expectedRole;
    
    if (user.role == 'SME' && role._routerState.url.includes('take-survey')) {
      flag = true;
    } else if (!expectedRole.includes(user.role)) {
      flag = false;
      this.router.navigate(['invalid']);
    } else {
      flag = true;
    }
    return flag;
  }

  isTokenExpired(): boolean {
    const token = JSON.parse(sessionStorage.getItem('tokens') || '{}');
    if (token && token.access) {
      const tokenKey = token.access.expires;
      const expirationDate = new Date(tokenKey);
      return expirationDate < new Date();
    }
    return true;
  }
}
